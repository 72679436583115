import "../portfolioPage.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import PatternLateral from "../../../components/patternLateral/PatternLateral"
import YoutubeVideo from "../../../components/portfolio/YouTubeVideo"
import NetflixLogo from "../../../svg/portfolio/wd/netflixLogo.svg"
import SonyPicsAnimationLogo from "../../../svg/portfolio/wd/sonyPicsAnimationLogo.svg"

export default function IndexPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout territory={"art"}>
      <Seo title={"Wish Dragon"} />
      <PatternLateral territory="art" />
      <h2 className="sectionTitle basic">
        <StaticImage
          src="../../../images/portfolio/wd-logo.png"
          className="portfolioFileTitle logoWD"
          alt={""}
        />
      </h2>

      <div className="content portfolioFileContent">
        <div className="companiesLogos">
          <NetflixLogo />
          <SonyPicsAnimationLogo />
        </div>

        <div className="filmDetails">
          <span>
            <Trans>Feature film service</Trans>
          </span>
          <span className="year">
            <Trans>2021</Trans>
          </span>
        </div>

        <YoutubeVideo videoId="FfubD6zbSsk" title={t("Wish Dragon Trailer")}>
          <p>
            <Trans>Wish Dragon - Official Trailer</Trans>
          </p>
        </YoutubeVideo>

        {/* TO-DO ADD LOGOS DE LOCUS Y NETFLIX (CHECK REEL) Y ALSO CHECK CON NADIA SI AÑADIMOS DISCLAIMER DE QUE ESTO ES UN SERVICE ETC */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
