import "./EmbedVideo.css"
import React from "react"

export default function YoutubeVideo({
  children,
  videoId,
  title,
  autoplay,
  aspectRatio,
}) {
  return (
    <div className="embedVideo ytVideo">
      <div
        style={{
          padding: `${aspectRatio * 100 || "56.25"}% 0 0 0`,
          position: "relative",
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0${
            autoplay ? "&autoplay=1&muted=1&loop=1" : ""
          }`}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {children}
    </div>
  )
}
